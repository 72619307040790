import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Country, CountryState, CustomerDetails } from "../../../types";

import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useNavigate } from "react-router-dom";
import "../../LocationStatus/styles.css";
import "./styles.css";
import {
  edit_icon_profile,
  kirkos_merchant_profile,
  manage_my_info_edit_logo,
  manage_my_profile_gen_info_icon,
  manage_my_profile_incomplete_logo,
  manage_my_profile_license_logo,
  manage_my_profile_location_logo,
  manage_my_profile_tick,
  pdf,
} from "../../../assets/images";
import CustomInput from "../../CustomInput";
import { useForm } from "react-hook-form";
import ImagePopupModal from "../../MerchantDashboard/ImagePopupModal";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import SuccessModal from "../../SuccessModal";

const ManageMyProfile = () => {
  const [email, setEmailAddress] = useState<string>("");
  const [firstName, setfirstName] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [license, setLicense] = useState<string>("");
  const [emiratesId, setEmiratesId] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [selectedCountryId, setSelectedCountryId] = useState<string>("");
  const [stateData, setStateData] = useState<CountryState[]>([]);
  const [state1, setState1] = useState<string>("");
  const [imageSource, setImageSource] = useState("");
  const [showImagePopupModal, setShowImagePopupModal] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [licenseImage, setLicenseImage] = useState("");
  const [emiratesImage, setEmiratesImage] = useState("");
  const [visaImage, setVisaImage] = useState("");
  const [passportImage, setPassportImage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const openFileInput = (documentType: string) => {
    setSelectedDocumentType(documentType);
    fileInputRef?.current?.click();
  };

  const [profileImage, setProfileImage] = useState<string>("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>("");
  const [newImage, setNewImage] = useState<string>("");

  const handleimagepopover = () => {
    setShowImagePopupModal(true);
  };
  const [address, setAddress] = useState<string>("");

  const { control, setValue, getValues } = useForm({ mode: "onChange" });

  const { data: countriesData } = useQuery<Country[], Error>({
    queryKey: ["countries"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.COUNTRY);
      return response.data;
    },
  });
  const handleUpdate = () => {
    const formValues = getValues();

    let customerDocs = [];

    if (customerData?.customerDocs) {
      if (licenseImage) {
        customerDocs.push({
          docId: licenseImage,
          docType: "LICENSE",
          docName: "LICENSE NAME",
        });
      }

      if (emiratesImage) {
        customerDocs.push({
          docId: emiratesImage,
          docType: "EMIRITIES",
          docName: "EMIRITIES NAME",
        });
      }

      if (visaImage) {
        customerDocs.push({
          docId: visaImage,
          docType: "VisitVisa",
          docName: "VISITING VISA",
        });
      }

      if (passportImage) {
        customerDocs.push({
          docId: passportImage,
          docType: "Passport",
          docName: "PASSPORT",
        });
      }
    }
    // let customerDocs = [];

    // if (customerData?.customerDocs) {
    //   customerDocs.push({
    //     docId: licenseImage,
    //     docType: "LICENSE",
    //     docName: "LICENCE NAME",
    //   });
    // }

    // if (customerData?.customerDocs) {
    //   customerDocs.push({
    //     docId: emiratesImage,
    //     docType: "EMIRITIES",
    //     docName: "EMIRITIES NAME",
    //   });
    // }

    // if (customerData?.customerDocs) {
    //   if (visaImage) {
    //     customerDocs.push({
    //       docId: visaImage,
    //       docType: "VisitVisa",
    //       docName: "VISITING VISA",
    //     });
    //   }
    // }

    // if (customerData?.customerDocs) {
    //   customerDocs.push({
    //     docId: passportImage,
    //     docType: "Passport",
    //     docName: "PASSPORT",
    //   });
    // }

    customerDocs = customerDocs.filter((doc) => doc.docId !== null);

    let body = {
      notification: true,
      offer: false,
      language: "en",
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      email: formValues.emailaddress,
      confirmEmail: "",
      licence: formValues.licensenumber,
      emiratesId: formValues.emiratesidnumber,
      address: formValues.contactAddress,
      country: formValues.country,
      state: formValues.state1,
      //customerDocs: customerDocs,
      customerDocs: customerDocs.length > 0 ? customerDocs : [],
      profilePicture: uploadedImageUrl || profileImage,
    };

    mutate(body);
  };

  const { mutate } = useMutation({
    mutationFn: (userData: any) =>
      axiosInstance.put(`${API_URL.PROFILE}`, userData),

    onSuccess: (successResponse) => {
      console.log("successResponseOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        console.log("vbnh", error);
      }
    },
  });

  const handleCountryChange = async (e: any) => {
    const selectedCountryName = e.target.value;
    setCountry(selectedCountryName);

    const selectedCountry = countriesData?.find(
      (country) => country.countryName === selectedCountryName
    );

    if (selectedCountry) {
      setSelectedCountryId(selectedCountry.countryId.toString());

      const response = await axiosInstance.get(
        `${API_URL.STATE}${selectedCountry.countryId}`
      );
      setStateData(response.data);
    }
  };

  const {
    data: customerData,
    isLoading,
    isError,
    error,
  } = useQuery<CustomerDetails, Error>({
    queryKey: ["detail"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.CUSTOMER_DETAILS);

      return response.data;
    },
  });

  useEffect(() => {
    const license = customerData?.customerDocs?.LICENSE;
    if (license !== undefined && license !== null && license !== "") {
      const documentId = customerData?.customerDocs?.LICENSE;
      axiosInstance
        .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("Response:", response);
          if (response && response.data) {
            console.log("dattatatattatatattata", response.data);
            const contentType = response.headers["content-type"];
            const isPdf = contentType === "application/pdf";
            const isImage = contentType.startsWith("image/");
            console.log("PDF ::: " + isPdf);
            if (isPdf) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectUrl = URL.createObjectURL(blob);
              const downloadLink = document.getElementById(
                "LICENSEDownloadLink"
              ) as HTMLAnchorElement;
              downloadLink.href = objectUrl;
              downloadLink.style.display = "block";
              downloadLink.download = "LICENSE.pdf";
              const LICENSE = document.getElementById("LICENSE");
              // LICENSE?.insertAdjacentText(
              //   "afterbegin",
              //   `Size: ${Number(response.data.byteLength) / 1000} KB`
              // );
              const imageElement = document.getElementById(
                "LICENSE_IMAGE"
              ) as HTMLImageElement;

              imageElement.src = pdf;
            } else if (isImage) {
              const blob = new Blob([response.data], { type: contentType });
              const imageUrl = URL.createObjectURL(blob);
              const imageElement = document.getElementById(
                "LICENSE_IMAGE"
              ) as HTMLImageElement;

              if (imageElement) {
                imageElement.src = imageUrl;
                imageElement.onclick = () => {
                  setImageSource(imageUrl);
                  setShowImagePopupModal(true);
                };
              }

              const imageSizeKB = response.data.byteLength / 1000;
              console.log("Image Size:", imageSizeKB, "KB");
            }
          } else {
            console.error("Response or data is undefined");
          }
        });
    }
  }, [customerData]);
  useEffect(() => {
    const license = customerData?.customerDocs?.Passport;
    if (license !== undefined && license !== null && license !== "") {
      const documentId = customerData?.customerDocs?.Passport;
      axiosInstance
        .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("Response:", response);
          if (response && response.data) {
            console.log("dattatatattatatattata", response.data);
            const contentType = response.headers["content-type"];
            const isPdf = contentType === "application/pdf";
            const isImage = contentType.startsWith("image/");
            console.log("PDF ::: " + isPdf);
            if (isPdf) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectUrl = URL.createObjectURL(blob);
              const downloadLink = document.getElementById(
                "PassportDownloadLink"
              ) as HTMLAnchorElement;
              downloadLink.href = objectUrl;
              downloadLink.style.display = "block";
              downloadLink.download = "Passport.pdf";
              const Passport = document.getElementById("LICENSE");
              // LICENSE?.insertAdjacentText(
              //   "afterbegin",
              //   `Size: ${Number(response.data.byteLength) / 1000} KB`
              // );
              const imageElement = document.getElementById(
                "PASSPORT_IMAGE"
              ) as HTMLImageElement;

              imageElement.src = pdf;
            } else if (isImage) {
              const blob = new Blob([response.data], { type: contentType });
              const imageUrl = URL.createObjectURL(blob);
              const imageElement = document.getElementById(
                "PASSPORT_IMAGE"
              ) as HTMLImageElement;

              if (imageElement) {
                imageElement.src = imageUrl;
                imageElement.onclick = () => {
                  setImageSource(imageUrl);
                  setShowImagePopupModal(true);
                };
              }

              const imageSizeKB = response.data.byteLength / 1000;
              console.log("Image Size:", imageSizeKB, "KB");
            }
          } else {
            console.error("Response or data is undefined");
          }
        });
    }
  }, [customerData]);
  useEffect(() => {
    if (customerData) {
      setProfileImage(customerData?.customer_details?.profile_picture);
      setEmiratesImage(customerData?.customerDocs?.EMIRITIES);
      setLicenseImage(customerData?.customerDocs?.LICENSE);
      setPassportImage(customerData?.customerDocs?.Passport);
      setVisaImage(customerData?.customerDocs?.VisitngVisa);
    }
  }, [customerData]);
  const handleFilePreview = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const previewImage = e.target.result as string;
        let imageElement: HTMLImageElement | null = null;

        if (selectedDocumentType == "license") {
          imageElement = document.getElementById(
            "LICENSE_IMAGE"
          ) as HTMLImageElement | null;
        } else if (selectedDocumentType == "emirates") {
          imageElement = document.getElementById(
            "EMIRITIES_IMAGE"
          ) as HTMLImageElement | null;
        } else if (selectedDocumentType == "visa") {
          imageElement = document.getElementById(
            "VISA_IMAGE"
          ) as HTMLImageElement | null;
        } else if (selectedDocumentType == "passport") {
          imageElement = document.getElementById(
            "PASSPORT_IMAGE"
          ) as HTMLImageElement | null;
        }

        if (imageElement) {
          imageElement.src = previewImage;
        }
      }
    };
    reader.readAsDataURL(file);
  };
  const handleEmiratesPreview = (file: File) => {
    // Modify the function signature to accept a File object
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const previewImage = e.target.result as string;
        const emiratesImage = document.getElementById(
          "EMIRITIES_IMAGE"
        ) as HTMLImageElement | null;
        if (emiratesImage) {
          // Update the src attribute based on the presence of a preview image
          emiratesImage.src = previewImage;
        }
      }
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    const license = customerData?.customerDocs?.VisitVisa;
    if (license !== undefined && license !== null && license !== "") {
      const documentId = customerData?.customerDocs?.VisitVisa;
      axiosInstance
        .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("Response:", response);
          if (response && response.data) {
            console.log("dattatatattatatattata", response.data);
            const contentType = response.headers["content-type"];
            const isPdf = contentType === "application/pdf";
            const isImage = contentType.startsWith("image/");
            console.log("PDF ::: " + isPdf);
            if (isPdf) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectUrl = URL.createObjectURL(blob);
              const downloadLink = document.getElementById(
                "VisitingVisaDownloadLink"
              ) as HTMLAnchorElement;
              downloadLink.href = objectUrl;
              downloadLink.style.display = "block";
              downloadLink.download = "VisitVisa.pdf";
              const EMIRITIES = document.getElementById("VisitVisa");
              // LICENSE?.insertAdjacentText(
              //   "afterbegin",
              //   `Size: ${Number(response.data.byteLength) / 1000} KB`
              // );
              const imageElement = document.getElementById(
                "VISA_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = pdf;
            } else if (isImage) {
              const blob = new Blob([response.data], { type: contentType });
              const imageUrl = URL.createObjectURL(blob);
              const imageElement = document.getElementById(
                "VISA_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              if (imageElement) {
                imageElement.src = imageUrl;
                imageElement.onclick = () => {
                  setImageSource(imageUrl);
                  setShowImagePopupModal(true);
                };
              }

              const imageSizeKB = response.data.byteLength / 1000;
              console.log("Image Size:", imageSizeKB, "KB");
            }
          } else {
            console.error("Response or data is undefined");
          }
        });
    }
  }, [customerData]);
  useEffect(() => {
    const license = customerData?.customerDocs?.EMIRITIES;
    if (license !== undefined && license !== null && license !== "") {
      const documentId = customerData?.customerDocs?.EMIRITIES;
      axiosInstance
        .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          console.log("Response:", response);
          if (response && response.data) {
            console.log("dattatatattatatattata", response.data);
            const contentType = response.headers["content-type"];
            const isPdf = contentType === "application/pdf";
            const isImage = contentType.startsWith("image/");
            console.log("PDF ::: " + isPdf);
            if (isPdf) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const objectUrl = URL.createObjectURL(blob);
              const downloadLink = document.getElementById(
                "EMIRITIESDownloadLink"
              ) as HTMLAnchorElement;
              downloadLink.href = objectUrl;
              downloadLink.style.display = "block";
              downloadLink.download = "EMIRITIES.pdf";
              const EMIRITIES = document.getElementById("EMIRITIES");
              // LICENSE?.insertAdjacentText(
              //   "afterbegin",
              //   `Size: ${Number(response.data.byteLength) / 1000} KB`
              // );
              const imageElement = document.getElementById(
                "EMIRITIES_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;

              imageElement.src = pdf;
            } else if (isImage) {
              const blob = new Blob([response.data], { type: contentType });
              const imageUrl = URL.createObjectURL(blob);
              const imageElement = document.getElementById(
                "EMIRITIES_IMAGE"
              ) as HTMLImageElement;
              // imageElement.src = imageUrl;
              if (imageElement) {
                imageElement.src = imageUrl;
                imageElement.onclick = () => {
                  setImageSource(imageUrl);
                  setShowImagePopupModal(true);
                };
              }

              const imageSizeKB = response.data.byteLength / 1000;
              console.log("Image Size:", imageSizeKB, "KB");
            }
          } else {
            console.error("Response or data is undefined");
          }
        });
    }
  }, [customerData]);

  useEffect(() => {
    if (!isLoading && customerData) {
      const { customer_details, kyc_and_bank_details, customerDocs } =
        customerData || {};
      const { firstName, lastName, phone_no, email, address, state, country } =
        customer_details || {};

      const { licence_number, emirates_id } = kyc_and_bank_details || {};
      // const { Passport, VisitVisa } = kyc_and_bank_details || {};

      setValue("contactAddress", address);
      setValue("firstname", firstName);
      setValue("lastname", lastName);
      setValue("phonenumber", phone_no);
      setValue("emailaddress", email);
      setCountry(String(country));
      const selectedCountry = countriesData?.find(
        (item) => String(item.countryName) === String(country)
      );

      if (selectedCountry) {
        setSelectedCountryId(selectedCountry.countryId.toString());

        axiosInstance
          .get(`${API_URL.STATE}${selectedCountry.countryId}`)
          .then((response) => {
            setStateData(response.data);
            setState1(String(state));
          })
          .catch((error) => {
            console.error("Error fetching state data:", error);
          });
      } else {
        setState1(String(state));
      }
      setValue("licensenumber", licence_number);
      setValue("emiratesidnumber", emirates_id);
      // setValue("passportnumber", Passport);
      // setValue("visitingvisa", VisitVisa);
    }
  }, [isLoading, customerData, countriesData]);

  const isInfoAvailable =
    customerData?.kyc_and_bank_details.emirates_id &&
    customerData?.customerDocs?.LICENSE;

  // const isImageAvailable =
  //   customerData?.customerDocs?.VisitVisa &&
  //   customerData?.customerDocs?.Passport;
  const isImageAvailable =
    (customerData?.customerDocs?.LICENSE &&
      customerData?.customerDocs?.VisitVisa &&
      customerData?.customerDocs?.Passport) ||
    (customerData?.customerDocs?.LICENSE &&
      customerData?.customerDocs?.EMIRITIES);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("product_image", file);

      try {
        const response = await axiosInstance.post(
          API_URL.PRODUCT_IMAGE_UPLOAD,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Image uploaded successfully:", response);

        if (response.data) {
          setNewImage(response.data);
          setUploadedImageUrl(response.data);
          console.log(uploadedImageUrl, "profffff");
        }
      } catch (error) {
        // Handle error
        console.error("Error uploading image:", error);
      }
    }
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById("profileImageInput");
    if (fileInput) {
      (fileInput as HTMLInputElement).click();
    }
  };
  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const [uploadedFile, setUploadedFile] = useState<string[]>([]);

  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleFileChange = async (files: File[], documentType: string) => {
    console.log("enteredd here---");
    const updatedImages = [...selectedImage];
    files.forEach((file) => {
      updatedImages.push(file.name);
    });

    setSelectedImage(updatedImages);
    let isSuccess = true; //
    for (const file of files) {
      const formData = new FormData();
      formData.append("document", file);

      try {
        const response = await axiosInstance.post(
          API_URL.DOC_UPLOAD,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const imageUrl = response.data.uuid;
        console.log("url--->", imageUrl);

        if (documentType == "license") {
          setLicenseImage(imageUrl);
          handleFilePreview(file);
        } else if (documentType == "emirates") {
          setEmiratesImage(imageUrl);
          handleFilePreview(file);
        } else if (documentType == "visa") {
          setVisaImage(imageUrl);
          handleFilePreview(file);
        } else if (documentType == "passport") {
          setPassportImage(imageUrl);
          handleFilePreview(file);
        }

        setUploadedFile((prevUploadedFiles) => [
          ...prevUploadedFiles,
          imageUrl,
        ]);
      } catch (error) {
        console.error("Error uploading image", error);
        isSuccess = false;
      }
    }
    // Handle success/error toast messages based on isSuccess
  };

  const handleDocChange = async (acceptedFiles: File[]) => {
    console.log("enteredd here---");
    const updatedImages = [...selectedImage];
    acceptedFiles.forEach((file) => {
      updatedImages.push(file.name);
    });

    setSelectedImage(updatedImages);
    let isSuccess = true; //
    for (const file of acceptedFiles) {
      const formData = new FormData();
      formData.append("document", file);

      try {
        const response = await axiosInstance.post(
          API_URL.DOC_UPLOAD,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const imageUrl = response.data.uuid;
        console.log("url--->", imageUrl);

        setEmiratesImage(imageUrl);
        handleEmiratesPreview(file);
        setUploadedFile((prevUploadedFiles) => [
          ...prevUploadedFiles,
          imageUrl,
        ]);
      } catch (error) {
        console.error("Error uploading image", error);
        isSuccess = false;
      }
    }
    if (isSuccess) {
      setIsSuccess(true);
      toast.success("Documents Uploaded Successfully!", {
        position: "top-right",
      });
    } else {
      setIsSuccess(false);
      toast.error("Error uploading documents. Please try again.", {
        position: "top-right",
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      console.log("hereeeee---->", selectedDocumentType);

      // Separate license and emirates files based on their names

      if (acceptedFiles.length > 0) {
        // Handle emirates files
        handleFileChange(acceptedFiles, selectedDocumentType);
      }
      if (acceptedFiles.length === 0) {
        toast.error("Please upload only image files.", {
          position: "top-right",
        });
      }
    },
    accept: "image/*" as any,
  });

  // const {} = useDropzone({
  //   onDrop: (acceptedFiles) => {
  //     const imageFiles = acceptedFiles.filter((file) =>
  //       file.type.startsWith("image/")
  //     );
  //     if (imageFiles.length > 0) {
  //       handleDocChange(imageFiles);
  //     } else {
  //       toast.error("Please upload only image files.", {
  //         position: "top-right",
  //       });
  //     }
  //   },re
  //   accept: "image/*" as any,
  // });

  return (
    <div className="row ">
      <div className="col-md-12 pt-4  ">
        <div className="row">
          <div className="col-md-7 my_profile_top_text text-start">
            Manage your Details, View your status, Edit your details , change
            your password....
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-4  text-center">
            <div className="card my_profile_box">
              <div className="card-body align-content-center">
                <div className="col-md-12 pt-4">
                  <div className="d-flex justify-content-center">
                    <div className="profile-img-div">
                      <img
                        src={
                          newImage || profileImage || kirkos_merchant_profile
                        }
                        width="110"
                        className="img-profile"
                        alt="Profile"
                      />
                      <input
                        type="file"
                        id="profileImageInput"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                      <div className="plus-icon-div" onClick={triggerFileInput}>
                        {/* <PencilFill className="plus-style" /> */}
                        <div className="plus-style">
                          <img src={edit_icon_profile} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 my_profile_name pt-1">{`${customerData?.customer_details.firstName} ${customerData?.customer_details.lastName}`}</div>
                <div className="col-md-12 d-flex justify-content-center my_profile_box_one">
                  <div className="my_profile_number ">
                    {customerData?.customer_details.phone_no}
                  </div>
                  <div className="ps-2 ">
                    <img src={manage_my_profile_tick} width="16" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8 my_profile_box_two text-start">
            <div className="card my_profile_box">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="pt-1">
                      <img src={manage_my_profile_gen_info_icon} width="23" />
                    </div>
                    <div className="my_profile_geninfo ps-2">
                      General Information
                    </div>
                  </div>
                  <div className="col-md-6 d-flex  justify-content-md-end">
                    <div className="my_profile_infoedit text-end">
                      Information Editable
                    </div>
                    <div className="ps-2">
                      <img src={manage_my_info_edit_logo} width="18" />
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-6 pt-3">
                    <div className="my_profile_input_text-lable ">
                      First Name <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="firstname"
                      inputType="firstname"
                      className="my_profile_input_field pt-1"
                      placeholder="Alex"
                      editable={true}
                      onInputChange={(value: string) => setfirstName(value)}
                      control={control}
                      // rules={rules.AuthRules.bank_name}
                    />
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className=" my_profile_input_text-lable">
                      Last Name <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="lastname"
                      inputType="lastname"
                      className="my_profile_input_field pt-1"
                      placeholder="John"
                      editable={true}
                      onInputChange={(value: string) => setlastName(value)}
                      control={control}
                      // rules={rules.AuthRules.bank_name}
                    />
                  </div>
                  <div className="col-md-12 pt-3">
                    <div className="my_profile_input_text-lable">
                      Email <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="emailaddress"
                      inputType="emailaddress"
                      className="my_profile_input_field pt-1"
                      placeholder="John"
                      editable={true}
                      onInputChange={(value: string) => setEmailAddress(value)}
                      control={control}
                      // rules={rules.AuthRules.bank_name}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row ">
          <div className="col-md-12 text-start pt-5">
            <div className="card my_profile_box">
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-md-12 d-flex">
                    <div className="pt-1">
                      <img src={manage_my_profile_license_logo} width="23" />
                    </div>
                    <div className="my_profile_geninfo ps-2">
                      License Details / Documents
                    </div>
                    {!isInfoAvailable && !isImageAvailable && (
                      <div className="ps-4">
                        <div className="my_profile_license_box  d-flex">
                          <div className="ps-2 pt-1">
                            <img
                              src={manage_my_profile_incomplete_logo}
                              width="23"
                            />
                          </div>
                          <div className="my_profile_incomplete_text px-2">
                            Currently your Documents are incomplete
                            <br />
                            Please update the Documents
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row pt-4">
                  {customerData?.customerDocs?.LICENSE ? (
                    <>
                      <div className="col-md-3">
                        <div className="my_profile_input_text"></div>
                        <div className="pt-3">
                          <div className="my_profile_input_text-lable">
                            License Number{" "}
                            <span className="merchantStar">*</span>
                          </div>
                          <CustomInput
                            inputName="licensenumber"
                            inputType="licensenumber"
                            className="my_profile_input_field pt-1"
                            placeholder="Enter your License Number"
                            editable={true}
                            onInputChange={(value: string) => setLicense(value)}
                            control={control}
                            // rules={rules.AuthRules.email}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 pt-3">
                        {customerData?.customerDocs?.LICENSE ? (
                          <>
                            <div className="row">
                              <div
                                id="license_container"
                                className="col-md-6 mb-2"
                              >
                                <p className="my_profile_input_text-lable">
                                  LICENSE:{" "}
                                </p>
                                <img
                                  id="LICENSE_IMAGE"
                                  style={{ width: 75, height: 75 }}
                                />
                                <p id="LICENSE"></p>
                                <a
                                  className="download-img"
                                  id="LICENSEDownloadLink"
                                  style={{ display: "none" }}
                                  title="Download LICENSE PDF"
                                >
                                  Download LICENSE PDF
                                </a>
                              </div>
                              <div className="pt-3">
                                <button
                                  onClick={() => openFileInput("license")}
                                  className="my_profile_upload_button text-center"
                                >
                                  Remove / Upload
                                </button>
                                <input
                                  {...getInputProps()}
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                  multiple
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <button className="my_profile_upload_button text-center">
                            Upload
                          </button>
                        )}
                      </div>

                      <div className="col-md-3 pt-3">
                        <div className="">
                          <div className="my_profile_input_text-lable">
                            Emirates ID Number{" "}
                            <span className="merchantStar">*</span>
                          </div>
                          <CustomInput
                            inputName="emiratesidnumber"
                            inputType="emiratesidnumber"
                            className="my_profile_input_field pt-1"
                            placeholder="Enter your ID Number"
                            editable={true}
                            onInputChange={(value: string) =>
                              setEmiratesId(value)
                            }
                            control={control}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 pt-3">
                        {customerData?.kyc_and_bank_details ? (
                          <>
                            <div className="row">
                              {customerData?.customerDocs && (
                                <div
                                  id="emirites_container"
                                  className="col-md-6 mb-2"
                                >
                                  <p className="my_profile_input_text-lable">
                                    EMIRATES:{" "}
                                  </p>
                                  <img
                                    id="EMIRITIES_IMAGE"
                                    style={{ width: 75, height: 75 }}
                                  />
                                  <p id="EMIRITIES"></p>
                                  <a
                                    className="download-img"
                                    id="EMIRITIESDownloadLink"
                                    title="Download EMIRITIES PDF"
                                    style={{ display: "none" }}
                                  >
                                    Download EMIRATES PDF
                                  </a>
                                </div>
                              )}
                            </div>
                            <div className="pt-3">
                              <button
                                onClick={() => openFileInput("emirates")}
                                className="my_profile_upload_button text-center"
                              >
                                Remove / Upload
                              </button>
                              <input
                                {...getInputProps()}
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                multiple
                              />
                            </div>
                          </>
                        ) : (
                          <button className="my_profile_upload_button text-center">
                            Remove / Upload
                          </button>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="row">
                      <div className="col-md-3 pt-3">
                        {customerData?.kyc_and_bank_details ? (
                          <>
                            <div className="row">
                              <div
                                id="license_container"
                                className="col-md-6 mb-2"
                              >
                                <p className="my_profile_input_text-lable">
                                  VISITING VISA:{" "}
                                </p>
                                <img
                                  id="VISA_IMAGE"
                                  style={{ width: 75, height: 75 }}
                                />
                                <p id="VISA"></p>
                                <a
                                  className="download-img"
                                  id="LICENSEDownloadLink"
                                  style={{ display: "none" }}
                                  title="Download LICENSE PDF"
                                >
                                  Download VISA PDF
                                </a>
                              </div>
                              <div className="pt-3">
                                <button
                                  onClick={() => openFileInput("visa")}
                                  className="my_profile_upload_button text-center"
                                >
                                  Remove / Upload
                                </button>
                                <input
                                  {...getInputProps()}
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                  multiple
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <button className="my_profile_upload_button text-center">
                            Upload
                          </button>
                        )}
                      </div>
                      <div className="col-md-3 pt-3">
                        {customerData?.kyc_and_bank_details ? (
                          <>
                            <div className="row">
                              {customerData?.customerDocs && (
                                <div
                                  id="emirites_container"
                                  className="col-md-6 mb-2"
                                >
                                  <p className="my_profile_input_text-lable">
                                    Passport:{" "}
                                  </p>
                                  <img
                                    id="PASSPORT_IMAGE"
                                    style={{ width: 75, height: 75 }}
                                  />
                                  <p id="EMIRITIES"></p>
                                  <a
                                    className="download-img"
                                    id="EMIRITIESDownloadLink"
                                    title="Download EMIRITIES PDF"
                                    style={{ display: "none" }}
                                  >
                                    Download PASSPORT PDF
                                  </a>
                                </div>
                              )}
                            </div>
                            <div className="pt-3">
                              <button
                                onClick={() => openFileInput("passport")}
                                className="my_profile_upload_button text-center"
                              >
                                Remove / Upload
                              </button>
                              <input
                                {...getInputProps()}
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                multiple
                              />
                            </div>
                          </>
                        ) : (
                          <button className="my_profile_upload_button text-center">
                            Remove / Upload
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {isImageAvailable && (
          <div className="row ">
            <div className="col-md-12 text-start pt-5">
              <div className="card my_profile_box">
                <div className="card-body">
                  <div className="row pb-3">
                    <div className="col-md-12 d-flex">
                      <div className="pt-1">
                        <img src={manage_my_profile_license_logo} width="23" />
                      </div>
                      <div className="my_profile_geninfo ps-2">
                        License Details / Documents
                      </div>
                      {!isInfoAvailable && !isImageAvailable && (
                        <div className="ps-4">
                          <div className="my_profile_license_box  d-flex">
                            <div className="ps-2 pt-1">
                              <img
                                src={manage_my_profile_incomplete_logo}
                                width="23"
                              />
                            </div>
                            <div className="my_profile_incomplete_text px-2">
                              Currently your Documents are incomplete
                              <br />
                              Please update the Documents
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row pt-4">
                    {customerData?.customerDocs?.LICENSE && (
                      <>
                        <div className="col-md-3">
                          <div className="my_profile_input_text"></div>
                          <div className="pt-3">
                            <div className="my_profile_input_text-lable">
                              License Number{" "}
                              <span className="merchantStar">*</span>
                            </div>
                            <CustomInput
                              inputName="licensenumber"
                              inputType="licensenumber"
                              className="my_profile_input_field pt-1"
                              placeholder="Enter your License Number"
                              editable={true}
                              onInputChange={(value: string) =>
                                setLicense(value)
                              }
                              control={control}
                              // rules={rules.AuthRules.email}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 pt-3">
                          <div className="row">
                            <div
                              id="license_container"
                              className="col-md-6 mb-2"
                            >
                              <p className="my_profile_input_text-lable">
                                LICENSE:{" "}
                              </p>
                              <img
                                id="LICENSE_IMAGE"
                                style={{ width: 75, height: 75 }}
                              />
                              <p id="LICENSE"></p>
                              <a
                                className="download-img"
                                id="LICENSEDownloadLink"
                                style={{ display: "none" }}
                                title="Download LICENSE PDF"
                              >
                                Download LICENSE PDF
                              </a>
                            </div>
                            <div className="pt-3">
                              <button
                                onClick={() => openFileInput("license")}
                                className="my_profile_upload_button text-center"
                              >
                                Remove / Upload
                              </button>
                              <input
                                {...getInputProps()}
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                multiple
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {customerData?.customerDocs?.EMIRITIES && (
                      <>
                        <div className="col-md-3">
                          <div className="my_profile_input_text"></div>
                          <div className="pt-3">
                            <div className="my_profile_input_text-lable">
                              Emirates ID Number{" "}
                              <span className="merchantStar">*</span>
                            </div>
                            <CustomInput
                              inputName="emiratesidnumber"
                              inputType="emiratesidnumber"
                              className="my_profile_input_field pt-1"
                              placeholder="Enter your ID Number"
                              editable={true}
                              onInputChange={(value: string) =>
                                setEmiratesId(value)
                              }
                              control={control}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 pt-3">
                          <div className="row">
                            <div
                              id="emirites_container"
                              className="col-md-6 mb-2"
                            >
                              <p className="my_profile_input_text-lable">
                                EMIRATES:{" "}
                              </p>
                              <img
                                id="EMIRITIES_IMAGE"
                                style={{ width: 75, height: 75 }}
                              />
                              <p id="EMIRITIES"></p>
                              <a
                                className="download-img"
                                id="EMIRITIESDownloadLink"
                                style={{ display: "none" }}
                                title="Download EMIRITIES PDF"
                              >
                                Download EMIRATES PDF
                              </a>
                            </div>
                            <div className="pt-3">
                              <button
                                onClick={() => openFileInput("emirates")}
                                className="my_profile_upload_button text-center"
                              >
                                Remove / Upload
                              </button>
                              <input
                                {...getInputProps()}
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                multiple
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {customerData?.customerDocs?.VisitVisa && (
                      <>
                        <div className="col-md-3 pt-3">
                          <div className="row">
                            <div
                              id="visitvisa_container"
                              className="col-md-6 mb-2"
                            >
                              <p className="my_profile_input_text-lable">
                                Visit Visa:{" "}
                              </p>
                              <img
                                id="VISA_IMAGE"
                                style={{ width: 75, height: 75 }}
                              />
                              <p id="VISA"></p>
                              <a
                                className="download-img"
                                id="VISADownloadLink"
                                style={{ display: "none" }}
                                title="Download Visit Visa PDF"
                              >
                                Download Visit Visa PDF
                              </a>
                            </div>
                            <div className="pt-3">
                              <button
                                onClick={() => openFileInput("visitvisa")}
                                className="my_profile_upload_button text-center"
                              >
                                Remove / Upload
                              </button>
                              <input
                                {...getInputProps()}
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                multiple
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {customerData?.customerDocs?.Passport && (
                      <>
                        <div className="col-md-3 pt-3">
                          <div className="row">
                            <div
                              id="passport_container"
                              className="col-md-6 mb-2"
                            >
                              <p className="my_profile_input_text-lable">
                                Passport:{" "}
                              </p>
                              <img
                                id="PASSPORT_IMAGE"
                                style={{ width: 75, height: 75 }}
                              />
                              <p id="PASSPORT"></p>
                              <a
                                className="download-img"
                                id="PASSPORTDownloadLink"
                                style={{ display: "none" }}
                                title="Download Passport PDF"
                              >
                                Download Passport PDF
                              </a>
                            </div>
                            <div className="pt-3">
                              <button
                                onClick={() => openFileInput("passport")}
                                className="my_profile_upload_button text-center"
                              >
                                Remove / Upload
                              </button>
                              <input
                                {...getInputProps()}
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                multiple
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* Add other document sections here */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row ">
          <div className="col-md-12 text-start pt-5">
            <div className="card my_profile_box">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="pt-1">
                      <img src={manage_my_profile_location_logo} width="23" />
                    </div>
                    <div className="my_profile_geninfo ps-2">
                      Contact Address
                    </div>
                  </div>
                  <div className="col-md-6 d-flex  justify-content-md-end">
                    <div className="my_profile_infoedit text-end">
                      Information Editable
                    </div>
                    <div className="ps-2">
                      <img src={manage_my_info_edit_logo} width="18" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 pt-3">
                    <div className="my_profile_input_text-lable">
                      Contact Address <span className="merchantStar">*</span>
                    </div>
                    <CustomInput
                      inputName="contactAddress"
                      inputType="contactAddress"
                      className="my_profile_input_field pt-1"
                      placeholder="E-70, Karama , Dubai"
                      editable={true}
                      onInputChange={(value: string) => setAddress(value)}
                      control={control}
                      // rules={rules.AuthRules.email}
                    />
                  </div>
                  <div className="col-md-4 pt-3">
                    <div className="my_profile_input_text-lable">
                      Country <span className="merchantStar">*</span>
                    </div>
                    <select
                      className="merchantinputStylex customWidths bg-black pt-1"
                      value={country}
                      onChange={handleCountryChange}
                    >
                      <option
                        value=""
                        key="select"
                        className="business_page_right_side_font"
                      >
                        Select country
                      </option>
                      {countriesData &&
                        countriesData.map((country) => (
                          <option
                            key={country.countryId}
                            value={country.countryName}
                          >
                            {country.countryName}
                          </option>
                        ))}
                    </select>
                  </div>
                  {/* <div className="col-md-5 d-md-flex justify-content-md-end"> */}
                  <div className="col-md-4 pt-3">
                    <div className="my_profile_input_text-lable">
                      State <span className="merchantStar">*</span>
                    </div>
                    <select
                      className="merchantinputStylex customWidths bg-black pt-1"
                      value={state1}
                      onChange={(e) => setState1(e.target.value)}
                    >
                      <option
                        value=""
                        key="select"
                        className="business_page_right_side_font"
                      >
                        Select state
                      </option>
                      {stateData &&
                        stateData.map((state) => (
                          <option key={state.id} value={state.state_name}>
                            {state.state_name}
                          </option>
                        ))}
                    </select>
                    {/* </div> */}
                  </div>
                </div>
                <div className="row float-end">
                  <div className="col-md-4 pt-5 pb-2">
                    <button
                      className=" btn all_button_style text-black"
                      onClick={handleUpdate}
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/Customer/MyAccount");
          setShowSuccessModal(false);
        }}
        message={"Updated Succesfully"}
      />
      <ImagePopupModal
        isOpen={showImagePopupModal}
        //  onClose={onCloseImagePopupModal}
        src={imageSource}
        onClose={() => setShowImagePopupModal(false)}
        message={""}
      />
    </div>
  );
};

export default ManageMyProfile;
