// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.curve-tab {
  border-radius: 10px; /* Adjust the border-radius to control the curve */
}
.dashboardtable {
  font-size: 12px;
  background-color: transparent;
  color: var(--font-color-primary);
  border: 0.5px solid #4a4f55;
  text-align: left;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
}
.dashboardtable td {
  font-size: 12px;
  background-color: transparent;
  color: var(--font-color-primary);
  font-family: "Poppins", sans-serif;
}
.dashboardtable th {
  font-size: 12px;
  background-color: var(--bg-colour-theme-56);
  color: var(--font-color-primary);
  font-family: "Poppins", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/MerchantDashboard/ProductDetails/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAE,kDAAkD;AACzE;AACA;EACE,eAAe;EACf,6BAA6B;EAC7B,gCAAgC;EAChC,2BAA2B;EAC3B,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,eAAe;EACf,6BAA6B;EAC7B,gCAAgC;EAChC,kCAAkC;AACpC;AACA;EACE,eAAe;EACf,2CAA2C;EAC3C,gCAAgC;EAChC,kCAAkC;AACpC","sourcesContent":[".curve-tab {\n  border-radius: 10px; /* Adjust the border-radius to control the curve */\n}\n.dashboardtable {\n  font-size: 12px;\n  background-color: transparent;\n  color: var(--font-color-primary);\n  border: 0.5px solid #4a4f55;\n  text-align: left;\n  width: 100%;\n  overflow-x: auto;\n  scrollbar-width: none;\n}\n.dashboardtable td {\n  font-size: 12px;\n  background-color: transparent;\n  color: var(--font-color-primary);\n  font-family: \"Poppins\", sans-serif;\n}\n.dashboardtable th {\n  font-size: 12px;\n  background-color: var(--bg-colour-theme-56);\n  color: var(--font-color-primary);\n  font-family: \"Poppins\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
